import React, {useState, useEffect} from "react"
import { navigate } from "gatsby"
import {Table, Form, FormControl, Alert, Toast, Button, Tooltip, OverlayTrigger, Row, Col, Badge} from 'react-bootstrap'

import Layout from "../components/layout"
import RightSidebar from "../components/sidebar/rightSideBar"
import SEO from "../components/seo";
import LocalStorage from "../components/localStorage";
import StatusDropdown from '../components/statusDropdown';
import StandardModal from '../components/modal';
import GenericPagination from '../components/genericPagination';
import ChangeLog from '../components/changeLog';
import Loader from '../components/loading/';
import GroupingFilter from '../components/filters/groupingFilter'
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';
import formatChangeLog from '../helpers/formatChangeLog';
import {replaceSpaceInPhoneNumber} from '../helpers/string';
import {DOSDDMMYYYYFormat, DDMMYYYYFormat, addDays, substractDays} from '../helpers/dateTime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JwtDecode, * as jwt from 'jwt-decode'
import { isRedDotBespoke } from '../helpers/roles'



const ProdSheet = ({location}) => {
  
  const decodedToken = LocalStorage.get('jwt') ? JwtDecode(LocalStorage.get('jwt')) : {};
  const isAdmin = decodedToken && decodedToken.role && decodedToken.role === 'admin' ? true : false;
  const isConsultant = decodedToken && decodedToken.role && decodedToken.role === 'consultant' ? true : false;
  const isManager = decodedToken && decodedToken.role && decodedToken.role === 'manager' ? true : false;

  const [prodArray, setProdArray] = useState([]);
  const [id, setId] = useState(0);
  const [status, setStatus]= useState('');
  const [show, setShow] = useState(false);
  const [pagination, setPagination] = useState({});
  const [custId, setCustId] = useState(location && location.state && location.state.custId ? location.state.custId : '');
  const [phone, setPhone] = useState('');
  const [orderId, setOrderId] = useState(location && location.state && location.state.orderId ? location.state.orderId : '');

  const [itemId, setItemId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showFGModal, setShowFGModal] = useState(false);
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(1);
  
  const [orderItem, setOrderItem] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [showUrgentDateRequired, setShowUrgentDateRequired] = useState(false);
  const [showUrgentDateEditmodal, setShowUrgentDateEditmodal] = useState(false);
  const [itemInByDate, setItemInByDate] = useState(new Date())

  const [consultants, setConsultants] = useState([]);
  const [consultantName, setConsultantName] = useState();

  const [orderGroupings, setOrderGroupings] = useState([]);
  const [orderGroupingFilter, setOrderGroupingFilter] = useState('');
  const [showRightSidebar, setshowRightSidebar] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isOkToBatchUpdate, setIsOkToBatchUpdate] = useState(false);
  
  const handleAlertClose = () => {
    setShowAlert(false);
  }

  const handleFGClose = () => {
    
    setShowFGModal(false);
  }
  
  const handleClose = () => {
    setShow(false);
    setShowUrgentDateRequired(false);
  }
  
  const handleRemarksClose = () => {
    setShowRemarksModal(false);
  }

  const handleItemInByDateModalClose = () => {
    setShowUrgentDateEditmodal(false)
  }

  const handleConfirm = () => {
    confirmFunction();
  }

  const handleItemInByDateModalConfirm = () => {
    updateItemInByDate();
    document.getElementById(`form${orderItem.id}`).classList.toggle('d-none')
    setShowUrgentDateEditmodal(false);
  }

  const handleFGConfirm =() => {
    const {
      id, 
      updated_on: updatedOn, 
      item_type: itemType, 
      production_type: productionType,
      urgent_date: urgentDate,
      custid,
    } = orderItem;
    const status = 'ProcessingFG';
    const data = {
      id,
      updatedOn,
      itemType,
      productionType,
      status,
      custid,
      sendSMS: false,
      fit_gtd: 1,
    }
    updateOrderItemStatus(data) 
    setShowFGModal(false);
  }

  const handleRemarksConfirm = () => {
    // Patch the remarks
    if (orderItem.remarks || orderItem.remarks === '') {
      const data = {
        remarks: orderItem.remarks
      }
      const url = `${constants.BACKEND_URL}/order-items/item/${orderItem.id}`;
      Fetch.patch(url, JSON.stringify(data))
      .then(data => {
        getProdSheetData();
        setShowRemarksModal(false);
      })
    }
  }

  const handleConfirmAndSendSMS = () => { 
    confirmFunction(true);
  }

  const formatItemForHTTPRequest = (item) => {
    const {
      id, 
      updated_on: updatedOn, 
      item_type: itemType, 
      production_type: productionType,
      urgent_date: urgentDate,
      item_in_by: itemInBy,
      custid,
    } = item;
    
    const data = {
      id,
      updatedOn,
      itemType,
      productionType,
      itemInBy,
      status,
      custid,
    }
    return data;
  }

  const confirmFunction = (sendSMS = false) => {
    const data = formatItemForHTTPRequest(orderItem);
    data.sendSMS = sendSMS;
    
    setShow(false);
    if (selectedItems.length >=2 && isOkToBatchUpdate) {
      batchUpdate(sendSMS);
      return;
    } 
    updateOrderItemStatus(data)
  }

  const handleItemInByDateEditClick = (e) => {
    const elements = document.getElementsByClassName('editItemInByDate');
    for(let i=0; i < elements.length; i++) {
      if (!elements[i].classList.contains('d-none')) {
        elements[i].classList.toggle('d-none');
      }
    }
    document.getElementById(`form${e.target.dataset.id}`).classList.toggle('d-none')
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
  }

  const handleOnDateFilterChange = (e) => {
    setItemInByDate(e)
    const itemInByObject = JSON.parse(orderItem.item_in_by);

    const newObject = {
      original: itemInByObject.original,
      changedDate: e,
      backwardBuffer: itemInByObject.backwardBuffer,
      forwardBuffer: itemInByObject.forwardBuffer,
    };
    
    const itemInBy2 = {
      item_in_by: JSON.stringify(newObject)
    }
    setOrderItem({...orderItem, ...itemInBy2})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = e.target.dataset.action;
    switch (action) {
      case 'save':
        setStatus(orderItem.status);
        setShowUrgentDateEditmodal(true);
        break;
      case 'cancel':
        document.getElementById(`form${e.target.dataset.id}`).classList.toggle('d-none')    
        break;
      default:
        break;
    }
  }

  const updateOrderItemStatus = (data) => {
    setShowLoader(true);
    Fetch.put(`${constants.BACKEND_URL}/order-items/prodsheet`, JSON.stringify(data)).then((res) => {
      if (!res.status) {
        setShowAlert(true)
        setAlertMessage({
          msg: res.error || res.msg
        })
        setShowLoader(false);
        setshowRightSidebar(false);
        return false;
      }
      setShowToast(true);
      getProdSheetData()
      setSelectedItems([]);
      setIsOkToBatchUpdate(false);
      setShowLoader(false);
    })
  }

  const batchUpdate = async (sendSMS = false) => {
    setShowLoader(true);
    for (let i = 0; i < selectedItems.length; i++) {
      const data = formatItemForHTTPRequest(selectedItems[i]);
      data.sendSMS = sendSMS;
      await Fetch.put(`${constants.BACKEND_URL}/order-items/prodsheet`, JSON.stringify(data));
      sendSMS = false; // Make sure to Send sms only one time
    }
    getProdSheetData();
    setSelectedItems([]);
    setIsOkToBatchUpdate(false);
    setShowLoader(false);
    setshowRightSidebar(false);
  }

  const updateItemInByDate = () => {
    const {id, item_in_by} = orderItem;
    const url = `${constants.BACKEND_URL}/order-items/item/${id}`
    const data = {
      itemInBy: item_in_by
    }

    setShowLoader(true);
    Fetch.patch(url,JSON.stringify(data)).then(res => {
      if (!res.status) {
        setShowLoader(false);
        return;
      }
      getProdSheetData()
      setShowLoader(false);
    })
  }

  const statusDropdown = (item) => {
    if (item.deposit <= 0 && item.balance > 0) {
      return (
        <div>
          <Badge variant="danger">No Deposit/Payment. Production not allowed</Badge>
        </div>
      )
    }
    return (
      <div> 
        {(!item.refund_status || item.refund_status.toLowerCase() === 'rejected') &&
          <StatusDropdown 
            item={item} 
            handleStatusDropdownChange={handleStatusDropdownChange}
          >
          </StatusDropdown>
        }
        {item.deposit <= 0 && item.balance <= 0 && 
          <div>
            <Badge variant="warning">Order is FOC</Badge>
          </div>
        }
      </div>
    )
  }

  const handleStatusDropdownChange = (e) => {
    if (e.target.value !== '') {
      setShow(true);
      setId(e.target.dataset.id)
      setStatus(e.target.value);
      setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
      resetDropdown(e)
    }
  }

  const handleOnSelectClick = (e) => {
    const {id} = e.target.dataset
    const selectedItemsCopy = [...selectedItems];
    
    const index = selectedItemsCopy.findIndex((item) => {
      return Number(item.id) === Number(id)
    })
    
    if (index !== -1) {
      selectedItemsCopy.splice(index,1);
    } else {
      selectedItemsCopy.push(getOrderItemFromProdArray(id));
    }

    let allCustIdAndStatusAreSame = true;
    if (selectedItemsCopy.length >= 2) {
      //Start comparing custid and status
      const custId = selectedItemsCopy[0].custid;
      const status = selectedItemsCopy[0].status;
      for (let i = 1; i < selectedItemsCopy.length; i++) {
        const element = selectedItemsCopy[i];
        
        if (Number(element.custid) !== Number(custId) || element.status !== status) {
          allCustIdAndStatusAreSame = false;
          break;
        }
      }
    }
    setIsOkToBatchUpdate(allCustIdAndStatusAreSame);
    setSelectedItems(selectedItemsCopy);
  }

  const changeLog = (log) => {
    return (
      <div>
        <ChangeLog log={log}></ChangeLog>
      </div>
    )
  }

  const getItemInByFromObject = (itemInByString) => {
    const itemInByObject = itemInByString ? JSON.parse(itemInByString) : null
    if (itemInByObject) {
      const itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate) : 
      new Date(itemInByObject.original);

      return itemInBy;
    }
  }

  const max120Field = (item) => {
    const max120 = new Date().getTime() - new Date(item.time).getTime()
    const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))

    return Difference_In_Days ? Difference_In_Days : '--';
  }

  const urgentDateField = (item) => {
    let urgentDate = item.urgent_date;
    if (!urgentDate || urgentDate === '0000-00-00') {
      return;
    }
    urgentDate = new Date(urgentDate.split('T')[0]).toLocaleString("en-SG").split(",")[0];
    return (
      <>
        {urgentDate}
      </>
    )
  }

  const itemInByDateField = (item) => {
    if (item.item_in_by && item.item_in_by.indexOf("1970") > -1 ) {
      item.item_in_by = null;
    }
    
    const itemInByObject = item.item_in_by ? JSON.parse(item.item_in_by) : null
    
    if (itemInByObject) {
      let itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate).toLocaleDateString("en-SG") : 
      new Date(itemInByObject.original).toLocaleDateString("en-SG");

      let fwdBuffer = itemInByObject && itemInByObject.forwardBuffer ? itemInByObject.forwardBuffer : 0;
      
      if (item.urgent_date && item.urgent_date != '0000-00-00' && item.urgent_date !== '') {
        if (addDays(itemInByObject.original, itemInByObject.forwardBuffer) < new Date(item.urgent_date)) {
          fwdBuffer = itemInByObject.forwardBuffer
        } else {
          const diff = new Date(item.urgent_date) - new Date(itemInByObject.original);
          fwdBuffer = Math.ceil(diff / (24 * 60 * 60 * 1000));
          // itemInBy = DDMMYYYYFormat(addDays(itemInByObject.original, fwdBuffer))
        }
      }

      let backwardBuffer = itemInByObject && itemInByObject.backwardBuffer ? itemInByObject.backwardBuffer : 0;

      if (substractDays(itemInByObject.original, itemInByObject.backwardBuffer) < new Date()) {
        const diff = new Date(itemInByObject.original) - new Date();
        if (diff > 0 ) {
          //Today is still less than original date
          backwardBuffer = Math.ceil(diff / (24 * 60 * 60 * 1000));
        }else{
          //Today has pass original date
          backwardBuffer = 0;
        }
      }

      return (
        itemInBy && item.status !== 'Collected' ?  (
          <>
            <div 
              className="pointer"
              data-id={item.id} 
              onClick={handleItemInByDateEditClick}>
              {itemInBy} 
            </div>
            <div>
              <Form noValidate className='editItemInByDate d-none' id={`form${item.id}`} data-id={item.id}>
                <Form.Group as={Row} className="mt-2">
                  <Col>
                    <DatePicker
                      className="reactDatePicker"
                      dateFormat="dd/MM/yyyy"
                      todayButton="Today"
                      selected={itemInByDate}
                      minDate={substractDays(itemInByObject.original, backwardBuffer)}
                      maxDate={addDays(itemInByObject.original, fwdBuffer)}
                      placeholderText="Select New Item In By Date"
                      onChange={handleOnDateFilterChange}
                      withPortal
                    />
                  </Col>
                </Form.Group>
                <Button className='mr-2' data-action="save" data-id={item.id} type="submit" onClick={handleSubmit}>Save</Button>
                <Button variant="secondary" data-action='cancel' data-id={item.id} type="submit" onClick={handleSubmit}>Cancel</Button>
              </Form>
            </div>
            
          </>
          ) : <></>
        
      )

    }
  }

  const remarksField = (item) => {
    return (
      <>
        {
          item.remarks && item.remarks.replace(/\s/g,'').length > 0 ? (
            <div className="pointer text-wrap" style={{"whiteSpace": "pre-line"}}data-id={item.id} onClick={handleOnEditRemarksClick}>
              {item.remarks.split ('\n').map (
                (line, i) => {
                  return <div data-id={item.id} key={i}>{line}</div> 
                }
              )}
            </div>
          ) : (
            <div ><span className="pointer oi oi-pencil" data-id={item.id} onClick={handleOnEditRemarksClick}></span></div>
          )
        }
      </>
    )
  }

  const handleOnRemarksChange = (e) => {
    const obj = {
      remarks: e.target.value 
    }
    setOrderItem({...orderItem, ...obj});
  }

  const handleOnEditRemarksClick = (e) => {
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    setShowRemarksModal(true)
  }

  const ActivateFitGtdButton = (item) => {
    if (item && item.status && item.status === 'Collected') {
      const now = new Date().getTime();
      const collectedTimestamp = getCollectedDateFromChangeLog(item.status_change_log);
      const twoWeeks = 1000 * 3600 * 24 * 7 * 2;
      if (now - collectedTimestamp < twoWeeks && !item.fit_gtd && !item.refunded && item.item_type !== 'ALTER') {
        return (
          <div>
            <Button variant="outline-secondary" onClick={() => showFitGuaranteedModal(item)}>Activate Fit Gtd</Button>
          </div>
        )
      }
    }
  }

  const getCollectedDateFromChangeLog = (changeLog) => {
    const collected = JSON.parse(changeLog).filter((log) => {
      return log.status === 'Collected';
    })
    const collectedTimeStamp = new Date(collected[0].timestamp).getTime()
    return collectedTimeStamp;
  }

  const showFitGuaranteedModal = (item) => {
    setShowFGModal(true);
    setOrderItem(item);
  }

  const resetDropdown = (e) => {
    e.target.value ='';
  }

  const handleOnConsultantChange = (e) => {
    setConsultantName(e.target.value);
    setPage(1);
  }

  const handleOnGroupingChange = (e) => {
    setOrderGroupingFilter(e.target.value);
    setPage(1);
  }

  const getGroupings = (params) => {
    Fetch.get(`${constants.BACKEND_URL}/order-groupings`)
    .then(res => {

      if(res.error) {
        setShowToast(true);
        // seterrorMessage(res.error);
        return;
      }
      setOrderGroupings(res.data);
    })
  }
  
  const getConsultants = () => {
    let url = '';
    if (isConsultant) {
      url = `${constants.BACKEND_URL}/consultants/${decodedToken.username}`
    } else {
      url = `${constants.BACKEND_URL}/consultants`
    }

    Fetch.get(url)
      .then(data => {
        if (data) setConsultants(data)
      })
  }
  
  const getProdSheetData = (limit = rowLimit) => {

    setShowLoader(true);
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/order-items/prodsheet`

    if (custId) {
      queryParams.push(`custId=${custId}`)
    }
    if (orderId) {
      queryParams.push(`orderId=${orderId}`)
    }
    if (itemId) {
      queryParams.push(`itemId=${itemId}`)
    }
    if (phone) {
      queryParams.push(`phone=${phone}`)
    }
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if (consultantName) {
      queryParams.push(`name=${consultantName}`)
    }
    if (orderGroupingFilter) {
      queryParams.push(`tag=${orderGroupingFilter}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }

    Fetch.get(`${url}${queryString}`)
    .then((data) => {
        setProdArray(data.data)
        setPagination(data.pagination);
        setShowLoader(false);
        setshowRightSidebar(false);
    }).catch((e) => {
      setShowLoader(false);
      navigate('/login');
      return false;
    })
  }

  const searchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const searchOrderId = (e) => {
    setOrderId(e.target.value);
    setPage(1);
  }

  const searchItemId = (e) => {
    setItemId(e.target.value);
    setPage(1);
  }

  const searchPhone = (e) => {
    const sanitizedPhone = replaceSpaceInPhoneNumber(e.target.value)
    setPhone(sanitizedPhone);
    setPage(1);
  }

  const ConsultantFilter = () => {
    return ((isAdmin || isManager) &&
      <Col>
        <Form.Group as={Row} >
          <Form.Label column sm={3}>Consultant</Form.Label>
          <Col>
            <Form.Control as="select" value={consultantName} onChange={handleOnConsultantChange}>
              <option value=''>--Select Consultant--</option>
              {
                consultants && Array.isArray(consultants) ? consultants.map(x => {
                  return (
                    <option key={x.name} >{x.name}</option>
                  )
                }) : <></>
              }
            </Form.Control>
          </Col>
        </Form.Group>
      </Col>
    )
  }
  
  useEffect(() => {
    getGroupings()
    getConsultants()
  },[]);

  // useEffect(() => {
  //   getProdSheetData()
  // },[]);

  useEffect(() => {
    if (custId || itemId || phone ) {
      const timeOutId = setTimeout(() => getProdSheetData(), constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getProdSheetData()
    }
  }, [custId, orderId, itemId, phone, consultantName, orderGroupingFilter, page]);

  const getOrderItemFromProdArray = (orderItemId) => {
    if (prodArray.length > 0) {
      const orderItem = prodArray.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const statusLabel = (item) => {
    return (
      item.status ? (
        <div>
          {item.fully_paid && item.status === constants.STATUS_COLLECTED ? (
            <Badge pill variant="success">{item.status}</Badge>
          ) : item.status}
        </div> ) : <></>
    )
  }

  const refundedLabel = (item) => {
    return (
      <>
        <div style={{'opacity':'0.6'}}>
          {item.refund_status === constants.REFUND_STATUS.PENDING && (
              <Badge pill variant="warning">Refund Pending</Badge>
          )}

          {item.refund_status === constants.REFUND_STATUS.APPROVED && (
              <Badge pill variant="info">Refund Approved</Badge>
          )}

          {item.refund_status === constants.REFUND_STATUS.REJECTED && (
              <Badge pill variant="secondary">Refund Rejected</Badge>
          )}
        </div>
      </>
    )
  }

  const statusList = (item) => {
    const status = statusLabel(item);
    const refunded = refundedLabel(item);

    return (
      <>
        {status}
        {refunded}
      </>
    )
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    setPage(1);
    if (!isConsultant) setConsultantName('')
    setCustId('')
    if (location && location.state ) location.state.custId = '';
    setOrderId('');
    setItemId('');
    setPhone('');
    setOrderGroupingFilter('');
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'consultantName':
        !isConsultant ? setConsultantName('') : setConsultantName(decodedToken.username);
        break;
      case 'custId':
        setCustId('')
        location.state.custId = ''
        break;
      case 'orderId':
        setOrderId('')
        break;
      case 'itemId':
        setItemId('')
        break;
      case 'phone':
        setPhone('')
        break;
      case 'orderGroupingFilter':
        setOrderGroupingFilter('')
        break;
      default:
        break;
    }
  }

  const toggleRightSidebar = (e) => {
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(orderItem.custid)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }

  const isSelectedItem = (id) => {
    const index = selectedItems.findIndex((item) => {
      return Number(item.id) === id
    })

    if (index !== -1) {
      return true;
    }
    return false;
  }

  const tableBody = () => {
    let tempId = 0;
    let whiteColor = true;
    return prodArray.map((item, index) => {
      if (index !== 0) {
        if (tempId !== item.custid) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (
        <tr key={item.id} className={`${rowBg}`}>
          {isRedDotBespoke() ? <td className="text-center"><Form.Check inline 
            label="" 
            type={'checkbox'} 
            id={`inline-checkbox-1`} 
            data-id={item.id}
            onClick={handleOnSelectClick}
            checked={isSelectedItem(item.id)}
          /></td> : <></>}
          <td>
            <span className="pointer" data-cust-id={item.custid} data-id={item.id} onClick={toggleRightSidebar}>{item.custid}</span>
          </td>
          <td>{item.id}</td>
          <td>{item.item_desc}</td>
          <td>{item.item_type}</td>
          <td>{item.setType}</td>
          <td>{statusList(item)}</td>
          <td>{itemInByDateField(item)}</td>
          {isRedDotBespoke() ? <td>{statusDropdown(item)}{ActivateFitGtdButton(item)}</td> : <></>}
          <td>{changeLog(item.status_change_log ? formatChangeLog(item.status_change_log) : '')}</td>
          <td>{remarksField(item)}</td>
        </tr>
      )
      tempId = item.custid;
      return result;
      
    })
  }

  return (
    <Layout pageInfo={{ pageName: "prodsheet" }}> 
      <Loader show={showLoader}></Loader>
      <SEO title="Production Sheet" />
      <Row>
        <Col className="text-center mb-2">
          <h5>Production Sheet</h5>
          <h6><small>- Showing Open Orders Only -</small></h6>
        </Col>
      </Row>
      <Row>
        <ConsultantFilter></ConsultantFilter>
        {isRedDotBespoke() ? <GroupingFilter
          show={true}
          orderGroupingFilter={orderGroupingFilter}
          handleOnGroupingChange={handleOnGroupingChange}
          orderGroupings={orderGroupings}
          withLabels
        ></GroupingFilter> : <></>}
        <Col>
          <Form.Group as={Row} className="mr-2 mb-2">
            <Form.Label column sm={3}>Cust ID</Form.Label>
            <Col>
              <FormControl
                type="text"
                placeholder="Search..." 
                value={custId}               
                onChange={searchCustId}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="mr-2 mb-2">
            <Form.Label column sm={3}>Item ID</Form.Label>
            <Col>
              <FormControl
                type="text"
                placeholder="Search..." 
                value={itemId}               
                onChange={searchItemId}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} >
            <Form.Label  column sm={3}>Cust HP</Form.Label>
            <Col>
              <FormControl
                type="text"
                placeholder="Search..." 
                value={phone}               
                onChange={searchPhone}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
          <span onClick={handleOnFilterPillClick}>
            {consultantName && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Consultant: {consultantName} 
                <span className="oi oi-circle-x ml-2" data-filter="consultantName" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {custId && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Cust ID: {custId} 
                <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {itemId && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Item ID: {itemId} 
                <span className="oi oi-circle-x ml-2" data-filter="itemId" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {phone && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Phone: {phone} 
                <span className="oi oi-circle-x ml-2" data-filter="phone" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {orderGroupingFilter && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Group: {orderGroupingFilter} 
                <span className="oi oi-circle-x ml-2" data-filter="orderGroupingFilter" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                {isRedDotBespoke() ? <th>Select</th> : <></>}
                <th>
                  Cust ID 
                  <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className='text-left' id={`tooltip`}>
                          Click on Cust Id to see additional info
                        </Tooltip>
                      }
                    >
                      <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                    </OverlayTrigger>
                </th>
                <th>Item ID</th>
                <th>FabID/Desc/Cont/Lin</th>
                <th>Item</th>
                <th>Set</th>
                <th>Status</th>
                <th>ItemInBy</th>
                {isRedDotBespoke() ? <th>Change Status</th> : <></>}
                <th>Log</th>
                <th style={{"width":"15%"}}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {prodArray && Array.isArray(prodArray) ? 
                tableBody() : <></>
              }
            </tbody>
          </Table>
        </Col>
      </Row>

      {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}

      <StandardModal 
        onConfirm={handleConfirm} 
        onClose={handleClose} 
        onConfirmAndSendSMS ={handleConfirmAndSendSMS}
        status={status} 
        show={show}
        title="Changing Order Items Status"
        body={``}
      > 
        <div>Order ID: <strong>{orderItem.order_id}</strong></div>
        <div>Customer ID: <strong>{orderItem.custid}</strong></div>
        {selectedItems && Array.isArray(selectedItems)  && selectedItems.length > 1  && isOkToBatchUpdate ?
          selectedItems.map((selectedItem) => {
            return (
              <>
              <div>ItemId: <strong>{selectedItem.id}</strong></div>
              </>
            )
          }) : (
            <>            
            <div>ItemId: <strong>{orderItem.id}</strong></div>
            </>
          )
        }
        <div>Are you sure you want to change item(s) status to <strong>{status.toUpperCase()}</strong>?</div>
      </StandardModal>

      <StandardModal 
        onClose={handleAlertClose} 
        show={showAlert}
        title="Alert Message"
        body=""
      >
        <Alert variant="danger" show={true}>
          <p>{alertMessage.msg}</p>
        </Alert>
      </StandardModal>

      <StandardModal 
        onConfirm={handleItemInByDateModalConfirm}
        onClose={handleItemInByDateModalClose} 
        show={showUrgentDateEditmodal}
        title="Changing urgent date/ item in by date "
        body={``}
      >
        <div>Order ID: <strong>{orderItem.order_id}</strong></div>
        <div>Customer ID: <strong>{orderItem.custid}</strong></div>
        <div>Are you sure you want to change urgent/item in by date to {DDMMYYYYFormat(getItemInByFromObject(orderItem.item_in_by))}?</div>
      </StandardModal>

      <StandardModal 
        onConfirm={handleFGConfirm}
        onClose={handleFGClose} 
        show={showFGModal}
        title="Fit Guaranteed button clicked"
        body={`Are you sure you want to Activate Fit Guarantee?`}
      >
      </StandardModal>


      <StandardModal 
        onConfirm={handleRemarksConfirm}
        onClose={handleRemarksClose} 
        show={showRemarksModal}
        title="Editing Remarks"
        body={``}
      >
        <Form.Group >
          <Form.Control as="textarea" rows="3" data-id={orderItem.id} onChange={handleOnRemarksChange} value={orderItem.remarks}>
          </Form.Control>
        </Form.Group>
      </StandardModal>

      <Toast 
        show={showToast} 
        onClose={() => {setShowToast(false)}}
        autohide
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">Update Success</strong>
        </Toast.Header>
      </Toast>
      
      <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={orderItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New - Cust ID</td>
                <td>{orderItem.custid}</td>
              </tr>
              <tr>
                <td>Old - Cust ID</td>
                <td>{orderItem.cust_id}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{orderItem.order_id}</td>
              </tr>
              <tr>
                <td>Group Name</td>
                <td>{orderItem.group_name}</td>
              </tr>
              <tr>
                <td>Consultant Name</td>
                <td>{orderItem.consultant_name}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{orderItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{orderItem.phone}</td>
              </tr>
              <tr>
                <td>DOS</td>
                <td>{DOSDDMMYYYYFormat(orderItem.time)}</td>
              </tr>
              <tr>
                <td>Max120 (days)</td>
                <td>{max120Field(orderItem)} </td>
              </tr>
              <tr>
                <td>Urgent Date</td>
                <td>{urgentDateField(orderItem)}</td>
              </tr>
            </tbody>
          </Table>
        </RightSidebar>
    </Layout>
  )
}

export default ProdSheet
